import Head from 'next/head';
import { useMemo } from 'react';

import { PAGE_DESCRIPTION } from '@/consts/meta';
import { useMatchMedia } from '@/hooks/use-match-media';

import type { JsonLd } from '@/consts/json-ld';

type HeadTemplateProps = {
  /** サイト内の各ページで個別の内容になるように設定 */
  pageTitle: string;
  /** サイト内で評価される正規URLをGoogleの検索エンジンに認識させるURL */
  pageCanonicalUrl?: string;
  /** サイト内の各ページで個別の内容になるように設定 */
  pageDescription?: string;
  /**
   * インデックス登録を避けるための判定フラグ（デフォルトは`true`）
   * - TOP画面以外はインデックス登録させたくないためデフォルトは`true`に設定する
   */
  isNoIndex?: boolean;
  /** Google推奨の構造化データに使用する`JSON-LD` */
  jsonLd?: JsonLd;

  /** OG画像の設定` */
  ogImage?: string;
};

/**
 * 各ページの`headタグ`を管理するコンポーネント
 * @param props {@link HeadTemplateProps}
 */
export const HeadTemplate = ({
  pageTitle,
  pageCanonicalUrl,
  pageDescription = PAGE_DESCRIPTION,
  isNoIndex = true,
  jsonLd,
  ogImage,
}: HeadTemplateProps) => {
  const { isSpSize } = useMatchMedia();

  const viewportContent = useMemo(() => {
    const baseContent = 'width=device-width,initial-scale=1.0,viewport-fit=cover';
    return isSpSize ? `${baseContent},maximum-scale=1.0` : baseContent;
  }, [isSpSize]);

  const metaPageCanonicalUrl = useMemo(
    () =>
      // MEMO(Yamazaki): プレビュー環境ではすべて`PageCanonicalUrl`を付与しない(nullで返す)
      pageCanonicalUrl !== undefined &&
      process.env.NEXT_PUBLIC_VERCEL_ENV !== 'preview' && <link rel='canonical' href={pageCanonicalUrl} />,
    [pageCanonicalUrl, process.env.NEXT_PUBLIC_VERCEL_ENV],
  );

  const metaRobotsIndex = useMemo(() => {
    // MEMO（kimura）: プレビュー環境ではすべて`noindex`を付与する
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview') return <meta name='robots' content='noindex' />;

    // MEMO（kimura）: 本番環境では`isNoIndex`のPropsに応じて出し分ける
    if (isNoIndex) {
      return <meta name='robots' content='noindex' />;
    } else {
      return <meta name='robots' content='index' />;
    }
  }, [process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview', isNoIndex]);

  return (
    <Head>
      <title>{pageTitle}</title>

      <meta charSet='UTF-8' />
      <meta name='description' content={pageDescription} />
      <meta name='viewport' content={viewportContent} />

      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='らくらく履歴書' />
      <meta property='og:title' content={pageTitle} />
      <meta property='og:description' content={pageDescription} />
      {pageCanonicalUrl !== undefined && <meta property='og:url' content={pageCanonicalUrl} />}

      {metaPageCanonicalUrl}
      {metaRobotsIndex}

      {ogImage !== undefined && <meta property='og:image' content={ogImage} />}
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='697' />

      <meta name='twitter:card' content='summary_large_image' />

      {/** Safariでホーム画面にお気に入りした場合、`apple-touch-icon.png`しか読み込んでくれないので以下を指定 */}
      <link rel='apple-touch-icon' sizes='180x180' href='favicons/apple-touch-icon.png' />
      {jsonLd !== undefined && (
        <script key='json-ld' type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
      )}
    </Head>
  );
};

export const PATHS = {
  /** トップページ */
  TOP: '/',
  /** マイページ */
  MY_PAGE: '/my-page',
  /** マイページ > 退会手続きページ */
  MY_PAGE_WITHDRAW: '/my-page/withdrawal',
  /** 利用規約ページ */
  TERMS: '/terms',
  /** プライバシーポリシーページ */
  PRIVACY_POLICY: '/privacypolicy',
  /** よくある質問ページ */
  FAQ: '/faq',
  /* 監修者ページ */
  SUPERVISOR: '/supervisor',
  /** 編集ポリシー */
  EDIT_POLICY: '/edit-policy',
  /** お問い合わせ */
  CONTACT: '/contact',
  /** ログイン画面 */
  SIGN_IN: '/account/signin',
  /** 登録画面 */
  SIGN_UP: '/account/signup',
  /** PW Reset Page */
  RESET_PASSWORD: '/account/password/reset',
  /** 使い方ページ */
  HOW_TO_USE: '/how-to-use',
  /** 職務履歴書作成ページ */
  CV: {
    /** 1. プロフィール入力ページ */
    PROFILE: '/cv/profile',
    /** 2. 職務要約ページ */
    SUMMARY: '/cv/summary',
    /** 3. スキルページ */
    SKILL: '/cv/skill',
    /** 4. 職歴ページ */
    HISTORY: '/cv/history',
    /** 5. 自己PR入力ページ */
    SELF_PROMOTION: '/cv/self-promotion',
    /** 6. 自己PR入力ページ */
    CREATE_DATE: '/cv/create-date',
    /** 7. 完成ページ */
    COMPLETE: '/cv/complete',
    /** 8. 履歴書をダウンロードページ */
    ACTION: '/cv/action',
    /** 9. アクション > 印刷設定ページ */
    ACTION_PRINT_CONFIG: '/cv/action/print-config',
    /** 10. アクション > 印刷準備完了ページ */
    ACTION_PRINT_COMPLETE: '/cv/action/print-complete',
    /** 10. アクション > PDF送信ページ */
    ACTION_SEND_PDF: '/cv/action/send-pdf',
  },
  /**
   * 共通の履歴書を作成するページ
   * - 一般用（JIS規格）の履歴書
   * - 転職用の履歴書（志望動機と自己PRは独自テンプレを使用）
   * - アルバイト・パート用の履歴書（志望動機と自己PRと本人希望欄は独自テンプレを使用）
   */
  RESUME_COMMON: {
    /** アクションページ */
    ACTION: '/resume-common/action',
    /** アクション > 印刷設定 > 履歴書の確認 */
    ACTION_PRINT_CONFIRM: '/resume-common/action/print-confirm',
    /** アクション > 印刷設定 > ユーザー番号発行 */
    ACTION_PRINT_CONFIG: '/resume-common/action/print-config',
    /** アクション > 印刷準備完了ページ */
    ACTION_PRINT_COMPLETE: '/resume-common/action/print-complete',
    /** アクション > PDF送信ページ */
    ACTION_SEND_PDF: '/resume-common/action/send-pdf',
    /** 住所入力ページ */
    ADDRESS: '/resume-common/address',
    /** 職歴入力ページ */
    CAREER: '/resume-common/career',
    /** 通勤時間等入力ページ */
    COMMUTE: '/resume-common/commute',
    /** 履歴書完成ページ */
    COMPLETE: '/resume-common/complete',
    /** 連絡先入力ページ */
    CONTACT: '/resume-common/contact',
    /** 学歴入力ページ */
    EDUCATION: '/resume-common/education',
    /** 証明写真入力ページ */
    ID_PICTURE: '/resume-common/id-picture',
    /** 資格・免許入力ページ */
    LICENSE: '/resume-common/license',
    /** プロフィール入力ページ */
    PROFILE: '/resume-common/profile',
    /** 本人希望欄入力ページ */
    SELF_HOPE: '/resume-common/self-hope',
    /** 自己PR入力ページ */
    SELF_PROMOTION: '/resume-common/self-promotion',
    /** 作成日入力ページ */
    CREATE_DATE: '/resume-common/create-date',
  },
  /** メディアページ */
  MEDIA_COMMON: {
    /** メディアトップ */
    MEDIA_TOP: '/media',
    /** 記事一覧トップ */
    ARTICLES: '/media/articles/',
    /** タグ一覧ページ */
    TAGS_PAGE: '/media/tags/',
  },
  // API routes
  API_ROUTES: {
    GENERATED_SELF_PROMOTION: '/generated-self-promotion',
  },
  RESUME_LINKS: {
    JIS_RESUME: '/format/jis-resume',
    MINISTRY_OF_HEALTH_RESUME: '/format/ministry-of-health',
    JOB_CHANGE_RESUME: '/format/job-change',
    NEW_GRADUATE_RESUME: '/format/new-graduate',
    PART_TIME_RESUME: '/format/part-time',
    CV: '/format/cv',
  },
} as const;

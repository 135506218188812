import { css } from '@emotion/react';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';

export const FORM_FOCUS_STYLE = css`
  &:focus-within {
    > label {
      color: ${COLOR.GREEN400};
    }

    > select {
      outline: none;
      border: solid 1px ${COLOR.GREEN400};
    }

    > input {
      outline: none;
      border: solid 1px ${COLOR.GREEN400};
    }

    > textarea {
      outline: none;
      border: solid 1px ${COLOR.GREEN400};
    }
  }
`;

export const FORM_ERROR_STYLE = css`
  > label {
    color: ${COLOR.RED400};
  }

  div > input {
    outline: none;
    border: solid 1px ${COLOR.RED400};
  }

  div > textarea {
    outline: none;
    border: solid 1px ${COLOR.RED400};
  }
`;

export const FORM_PLACEHOLDER_STYLE = css`
  &::placeholder {
    font-size: ${FONT_SIZE.SIZE_14};
    color: ${COLOR.GRAY400};
  }
`;

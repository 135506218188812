export const PAGE_TITLE = 'らくらく履歴書';
export const PAGE_FULL_TITLE =
  '履歴書・職務履歴書をスマホ・パソコンのブラウザで楽に作るなら、らくらく履歴書 | 豊富なテンプレートと、PDF・コンビニ印刷対応';

export const PAGE_DESCRIPTION =
  'らくらく履歴書はスマホ・パソコンのWeb・アプリで簡単に履歴書・職務経歴書が作成できる無料サービスです。入力補助機能がついた履歴書・職務経歴書のテンプレートを選んで入力するだけ。作成した履歴書は、PDF出力やコンビニで印刷をすることができます。';
export const PAGE_MEDIA_TITLE =
  '履歴書・職務経歴書は、応募者の学歴・職歴をはじめ、志望動機や希望する職種、就業場所を確認するための、選考のうえで重要となる書類です。このサイトでは、基本的なテンプレートのダウンロードが可能な他、履歴書作成に必要な準備から、書き方、送り方などをわかりやすく解説しています。';
export const PAGE_MEDIA_CATEGORY_META_DEFAULT =
  '履歴書・職務経歴書は、応募者の学歴・職歴をはじめ、志望動機や希望する職種、就業場所を確認するための、選考のうえで重要となる書類です。このページではカテゴリの一覧を表示しています。';

export const PAGE_MEDIA_CATEGORY_META = [
  {
    id: 13,
    description:
      '履歴書・職務経歴書は、応募者の学歴・職歴をはじめ、志望動機や希望する職種、就業場所を確認するための、選考のうえで重要となる書類です。このページでは、履歴書の基本的な形式やレイアウトを示したテンプレートの提供や、テンプレートを利用した作成方法をまとめ、効率的な履歴書作成をサポートします。',
  },
  {
    id: 14,
    description:
      '履歴書・職務経歴書は、応募者の学歴・職歴をはじめ、志望動機や希望する職種、就業場所を確認するための、選考のうえで重要となる書類です。このページでは、履歴書の各項目の記入方法や注意点、アピールポイントなどを解説し、効果的な履歴書の書き方をお伝えします。',
  },
  {
    id: 15,
    description:
      '履歴書・職務経歴書は、応募者の学歴・職歴をはじめ、志望動機や希望する職種、就業場所を確認するための、選考のうえで重要となる書類です。このページでは、履歴書を作成する前に必要な情報収集や、写真の用意、下書きの作成など、履歴書作成の準備段階について解説します。',
  },
  {
    id: 16,
    description:
      '履歴書・職務経歴書は、応募者の学歴・職歴をはじめ、志望動機や希望する職種、就業場所を確認するための、選考のうえで重要となる書類です。このページでは、様々な業種・職種に応じた履歴書の記入例を紹介し、具体的なイメージを持って履歴書を作成できるようサポートします。',
  },
  {
    id: 17,
    description:
      '就職活動において、履歴書は応募者の学歴・職歴をはじめ、志望動機や希望する職種、就業場所を確認するための、選考のうえで重要となる書類です。このページでは、就活生が知っておくべき履歴書の基本や、就活に特化した履歴書の書き方について解説します。',
  },
];

import { useEffect, useMemo, useState } from 'react';

import { isClient } from '@/consts/device';
import { BREAK_POINTS } from '@/styles/media';

const hoverQuery = '(any-hover: hover)';
const pcQuery = '(max-width:1024px)';
const tabletQuery = '(max-width:768px)';
const spQuery = '(max-width:599px)';

/**
 * ### useMatchMedia Custom Hooks
 *
 * - 1025px以上 : PC → isPcSize が `true` になる
 * - 600px 〜 1024px : Tablet → isTabletSize が `true` になる
 * - 599px以下 : Smart Phone → isSpSize が `true` になる
 * - ホバー可能なデバイスの場合 : availableHover が `true` になる
 */
/**
 * @deprecated Reason: Hydration Error の原因となっているため
 */
export const useMatchMedia = () => {
  const defaultWindowWidth = useMemo(() => (isClient ? window.innerWidth : 0), [isClient]);
  const isPcSize = useMemo(() => defaultWindowWidth >= BREAK_POINTS.pc, [defaultWindowWidth]);
  const isTabletSize = useMemo(
    () => BREAK_POINTS.tablet <= defaultWindowWidth && defaultWindowWidth < BREAK_POINTS.pc,
    [defaultWindowWidth],
  );
  const isSpSize = useMemo(() => defaultWindowWidth <= BREAK_POINTS.sp, [defaultWindowWidth]);
  const availableHover = useMemo(() => isClient && window.matchMedia(hoverQuery).matches, [isClient]);

  const [mediaType, setMediaType] = useState({
    isSpSize,
    isTabletSize,
    isPcSize,
    availableHover,
  });

  const onChangeMediaByDirection = (event: MediaQueryListEvent) => {
    setMediaType((prev) => ({ ...prev, isVertical: event.matches }));
  };

  const onChangeMediaByPcWidth = (event: MediaQueryListEvent) => {
    setMediaType((prev) => ({
      ...prev,
      isPcSize: !event.matches,
      isTabletSize: event.matches,
    }));
  };

  const onChangeMediaBySpWidth = (event: MediaQueryListEvent) => {
    setMediaType((prev) => ({
      ...prev,
      isTabletSize: !event.matches,
      isSpSize: event.matches,
    }));
  };

  const onChangeMediaByHover = (event: MediaQueryListEvent) => {
    setMediaType((prev) => ({ ...prev, availableHover: event.matches }));
  };

  useEffect(() => {
    const pcMediaQueryList = window.matchMedia(pcQuery);
    const spMediaQueryList = window.matchMedia(spQuery);
    const hoverMediaQueryList = window.matchMedia(hoverQuery);
    const tabletMediaQueryList = window.matchMedia(tabletQuery);

    pcMediaQueryList.addEventListener('change', onChangeMediaByPcWidth, {
      passive: true,
    });
    spMediaQueryList.addEventListener('change', onChangeMediaBySpWidth, {
      passive: true,
    });
    hoverMediaQueryList.addEventListener('change', onChangeMediaByHover, {
      passive: true,
    });
    tabletMediaQueryList.addEventListener('change', onChangeMediaByDirection, {
      passive: true,
    });

    return () => {
      pcMediaQueryList.removeEventListener('change', onChangeMediaByPcWidth);
      spMediaQueryList.removeEventListener('change', onChangeMediaBySpWidth);
      hoverMediaQueryList.removeEventListener('change', onChangeMediaByHover);
      tabletMediaQueryList.removeEventListener('change', onChangeMediaByHover);
    };
  }, []);

  return mediaType;
};
